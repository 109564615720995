import Layout from "../components/layout"
import Seo from "../components/seo"
import React, { useEffect } from "react"
import PrivacyPolicyPage from "../components/PrivacyPolicyPage"
import { useIntl, navigate } from "gatsby-plugin-react-intl"

const Page = () => {
  const intl = useIntl()
  const lang = intl.locale

  useEffect(() => {
    if (lang === "en") {
      navigate("/privacy_policy/")
    }
  }, [lang])

  return (
    <Layout>
      <Seo title="Polityka Prywatności" />
      <PrivacyPolicyPage />
    </Layout>
  )
}
export default Page
